var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "35.94",
        height: "16.718",
        viewBox: "0 0 35.94 16.718"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(0 -0.003)" } }, [
        _c("circle", {
          staticClass: "a",
          attrs: {
            cx: "2.16",
            cy: "2.16",
            r: "2.16",
            transform: "translate(7.21 11.9)"
          }
        }),
        _c("circle", {
          staticClass: "a",
          attrs: {
            cx: "2.16",
            cy: "2.16",
            r: "2.16",
            transform: "translate(24.56 11.9)"
          }
        }),
        _c("path", {
          staticClass: "b",
          attrs: {
            d:
              "M31.92,14.06h.97a2.417,2.417,0,0,0,1.8-.8l.14-.16a2.417,2.417,0,0,0,.61-1.6V8.99a4.238,4.238,0,0,0-3.5-4.18l-2.07-.37c-.12-.19-.25-.38-.39-.56A9.016,9.016,0,0,0,22.4.51C19.7.5,16,.48,14.56.56c-2.4.13-5.78,4.07-5.78,4.07A14.148,14.148,0,0,0,2.94,5.81,4.212,4.212,0,0,0,.5,9.66v1.99a2.413,2.413,0,0,0,1.02,1.97h0a2.391,2.391,0,0,0,1.39.44H4.25"
          }
        }),
        _c("line", {
          staticClass: "b",
          attrs: { x2: "6.88", transform: "translate(14.61 14.06)" }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }